/**
 *
 * DrBannerThemeProvider
 *
 */

import React from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { decomposeColor, emphasize, fade } from '@material-ui/core/styles/colorManipulator';

export const drbTheme = {
  palette: {
    common: {
      grey: '#000000',
      pink: '#D729B1',
      orange: '#D87C02',
      black: '#313131',
    },
    primary: {
      main: '#2cb8c4',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FFCF15',
      contrastText: '#998016',
    },
    background: {
      default: '#FAFAFA',
    },
    text: {
      secondary: '#000000',
    },
    error: {
      main: '#CB2520',
    },
  },
  shape: {
    borderRadius: 3,
  },
  typography: {
    fontWeightBold: 'bold',
    fontFamily2: 'Arial, sans-serif',
    h1: {
      fontFamily: 'Arial, sans-serif',
    },
    h2: {
      fontFamily: 'Arial, sans-serif',
    },
    h3: {
      fontFamily: 'Arial, sans-serif',
    },
    h4: {
      fontFamily: 'Arial, sans-serif',
    },
    h5: {
      fontFamily: 'Arial, sans-serif',
    },
    h6: {
      fontFamily: 'Arial, sans-serif',
      fontWeight: 'bold',
    },
    subtitle1: {
      fontFamily: 'Arial, sans-serif',
      fontWeight: 'bold',
    },
    body2: {
      color: '#9B9B9B',
    },
    body1: {
      fontSize: '0.875rem',
      color: '#000000',
    },
  },
  custom: {
    map: {
      defaultControlButton: {
        backgroundColor: '#9B9B9B',
        '&:hover': {
          backgroundColor: '#8a8a8a',
        },
      },
      controlButton: {
        backgroundColor: '#ff5722',
        '&:hover': {
          backgroundColor: '#c41c00',
        },
      },
      controlButtonActive: {
        backgroundColor: '#c41c00',
      },
    },
  },
};

const drbOverridesMui = adBuilder => theme => ({
  overrides: {
    MuiDialogTitle: {
      root: {
        paddingTop: theme.spacing(4),
        paddingRight: theme.spacing(8),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(8),
        textAlign: 'center',
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: `12px !important`,
      },
    },
    MuiDialogContent: {
      root: {
        paddingRight: theme.spacing(12),
        paddingLeft: theme.spacing(12),
      },
    },
    MuiDialogContentText: {
      root: {
        textAlign: 'center',
        fontWeight: 'normal',
      },
    },
    MuiDialogActions: {
      root: {
        margin: 0,
        marginTop: theme.spacing(1),
        paddingRight: theme.spacing(11),
        paddingBottom: theme.spacing(adBuilder ? 4 : 6), // modified by adbuilder
        paddingLeft: theme.spacing(11),
      },
    },
    MuiChip: {
      root: {
        color: theme.palette.primary.main,
        backgroundColor: fade(theme.palette.primary.main, 0.2),
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
      },
      deleteIcon: {
        color: theme.palette.common.white,
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          color: theme.palette.common.white,
          '&$selected, &$selected:hover': {
            backgroundColor: theme.palette.primary.main,
          },
        },
      },
      button: {
        '&:hover': adBuilder
          ? {}
          : {
              color: theme.palette.common.white,
              backgroundColor: theme.palette.primary.main,
            },
      },
    },
    MuiPaper: {
      root: {
        borderRadius: 10,
      },
      elevation1: {
        boxShadow: '0 1px 4px 1px rgba(155,155,155,0.17)',
      },
      elevation2: {
        boxShadow: '3px 5px 10px 5px rgba(0,0,0,0.08)',
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      contained: {
        boxShadow: '0 2px 4px 0 rgba(0,0,0,0.21)',
      },
    },
    MuiTabs: {
      root: {
        minHeight: 32,
        boxShadow: '0 2px 3px 0 rgba(203,203,203,0.45)',
      },
      indicator: {
        height: 3,
        backgroundColor: theme.palette.primary.main,
      },
    },
    MuiTab: {
      root: {
        '&$selected': {
          color: theme.palette.primary.main,
        },
        width: 'auto',
        paddingRight: 0,
        paddingLeft: theme.spacing(),
        textAlign: 'left',
        [theme.breakpoints.up('md')]: {
          paddingRight: 0,
          paddingLeft: theme.spacing(),
        },
      },
      textColorInherit: {
        color: theme.palette.common.grey,
      },
      wrapper: adBuilder
        ? {
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }
        : {
            flexDirection: 'row',
            justifyContent: 'flex-start',
            '& *:first-child': {
              marginRight: theme.spacing(2),
              marginBottom: '1px !important',
            },
          },
      labelIcon: {
        minHeight: 48,
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
      },
    },
    MuiMenuItem: {
      root: {
        '&:hover': {
          color: theme.palette.common.white,
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    MuiLinearProgress: {
      colorSecondary: {
        backgroundColor: '#F6F7F8',
      },
    },
    MuiExpansionPanel: {
      root: {
        borderRadius: 3,
        boxShadow: '0 1px 4px 1px rgba(155,155,155,0.2)',
        border: '1px solid transparent',
        '&$expanded': adBuilder
          ? {
              margin: 0,
            }
          : {
              boxShadow: `0 1px 4px 3px rgba(${decomposeColor(emphasize(theme.palette.primary.main, 0.2)).values.join(
                ',',
              )},0.2)`,
              border: `1px solid ${theme.palette.primary.main}`,
            },
      },
    },
    MuiInput: {
      root: adBuilder
        ? {
            color: '#000000', // modified by adbuilder
          }
        : {},
      underline: {
        '&:before': {
          borderColor: theme.palette.common.grey,
        },
        '&:hover:not($disabled):not($focused):not($error):before': {
          borderColor: theme.palette.common.grey,
          '@media (hover: none)': {
            borderColor: theme.palette.common.grey,
          },
        },
      },
    },
    MuiFormLabel: {
      root: adBuilder
        ? {
            color: '#9B9B9B',
          }
        : {},
    },
    MuiPickersModal: {
      withAdditionalAction: {
        display: 'none',
      },
    },
  },
});

export const generateTheme = (theme, overrideMui) => {
  return createMuiTheme({
    ...theme,
    ...overrideMui(createMuiTheme(theme)),
  });
};

const DrBannerThemeProvider = ({ children, userTheme, adBuilder }) => {
  const theme = generateTheme(
    {
      ...drbTheme,
      palette: {
        ...drbTheme.palette,
        primary: (userTheme.primaryColor && { main: userTheme.primaryColor }) || drbTheme.palette.primary,
        secondary: (userTheme.secondaryColor && { main: userTheme.secondaryColor }) || drbTheme.palette.secondary,
      },
    },
    drbOverridesMui(adBuilder),
  );

  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

DrBannerThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
  userTheme: PropTypes.shape({
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }).isRequired,
  adBuilder: PropTypes.bool,
};

DrBannerThemeProvider.defaultProps = {
  userTheme: {
    primaryColor: '#2cb8c4',
    secondaryColor: '#ffcc19',
  },
  adBuilder: false,
};

export default React.memo(DrBannerThemeProvider);
