import React from 'react';
import { IconButton, Menu as MuiMenu, MenuItem } from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

const menuPosition = { vertical: 'top', horizontal: 'right' };

class EntityMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleOpen = e => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ anchorEl: e.currentTarget });
  };

  handleClose = (action = false) => e => {
    e.stopPropagation();
    this.setState({ anchorEl: null }, () => action && action());
  };

  render() {
    const { node, menuDisabled, parentNode, classes, menuItems, icon, ...others } = this.props;
    const { anchorEl } = this.state;

    const showMenu = Boolean(menuItems && menuItems.length);
    if (!showMenu) return null;

    return (
      <React.Fragment>
        <IconButton
          color="primary"
          edge="end"
          onClick={this.handleOpen}
          disabled={menuDisabled || (parentNode && !parentNode.active)}
        >
          {icon || <MoreVertIcon color="primary" />}
        </IconButton>
        <MuiMenu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose()}
          classes={{ paper: classes.menuPaper }}
          anchorOrigin={menuPosition}
          transformOrigin={menuPosition}
        >
          {menuItems
            .map(({ label, Icon, action }) => ({ label, Icon, action: () => action(node) }))
            .map(({ label, Icon, action }) => (
              <MenuItem key={label} onClick={this.handleClose(action)}>
                {Icon && <Icon className={classes.menuIconLeft} />}
                {label}
              </MenuItem>
            ))}
        </MuiMenu>
      </React.Fragment>
    );
  }
}

EntityMenu.propTypes = {
  classes: PropTypes.object.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      Icon: PropTypes.node,
      action: PropTypes.func,
    }),
  ).isRequired,
};

export default EntityMenu;
