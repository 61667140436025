import React from 'react';
import { withTheme, SvgIcon } from '@material-ui/core';

const TreeCollapseIcon = ({ theme, ...props }) => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <svg width="22" height="22" viewBox="0 0 22 22">
      <g fill="none">
        <circle cx="11" cy="11" r="11" fill={theme.palette.primary.main} />
        <rect width="11" height="1" x="5.5" y="10.5" fill="#FFF" />
      </g>
    </svg>
  </SvgIcon>
);

export default withTheme(TreeCollapseIcon);
