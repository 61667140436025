import React from 'react';
import { SvgIcon } from '@material-ui/core';

const TreeMoreIcon = ({ ...props }) => (
  <SvgIcon viewBox="0 0 9 35" {...props}>
    <path
      fill="#ECECEC"
      d="M4.50074058,8.32616057 C2.29160158,8.32616057 0.50074058,6.53529957 0.50074058,4.32616057 C0.50074058,2.11702157 2.29160158,0.326160572 4.50074058,0.326160572 C6.70987958,0.326160572 8.50074058,2.11702157 8.50074058,4.32616057 C8.50074058,6.53529957 6.70987958,8.32616057 4.50074058,8.32616057 Z M4.50074058,21.6630803 C2.29160158,21.6630803 0.50074058,19.8722193 0.50074058,17.6630803 C0.50074058,15.4539413 2.29160158,13.6630803 4.50074058,13.6630803 C6.70987958,13.6630803 8.50074058,15.4539413 8.50074058,17.6630803 C8.50074058,19.8722193 6.70987958,21.6630803 4.50074058,21.6630803 Z M4.50074058,35 C2.29160158,35 0.50074058,33.209139 0.50074058,31 C0.50074058,28.790861 2.29160158,27 4.50074058,27 C6.70987958,27 8.50074058,28.790861 8.50074058,31 C8.50074058,33.209139 6.70987958,35 4.50074058,35 Z"
    />
  </SvgIcon>
);

export default TreeMoreIcon;
