import moment from 'moment';

import { extractPermissions } from './extractPermissions';
import { baseSearchUrl, queryInit } from './urls';
import { doWithToken } from '../keycloak';

const pageSize = 30;
const bannerSetThumbnailName = 'thumbnail'; // 300_250_thumbnail.png or 300_250_thumbnail..jpeg

export const formatResponse = ({ entries, numberOfPages }) => ({
  numberOfPages,
  bannerSets: entries.map(entry => {
    const { uid, properties, path, lockOwner } = entry;
    const thumbnail =
      properties['files:files'] &&
      properties['files:files'].filter(
        el =>
          (el && el.file && el.file.name === `${bannerSetThumbnailName}.png`) ||
          el.file.name === `${bannerSetThumbnailName}.jpeg`,
      );
    const thumbnailUrl = thumbnail && thumbnail[0] && thumbnail[0].file && thumbnail[0].file.data;
    return {
      metadataUrl: properties['file:content'] && properties['file:content'].data,
      name: properties['dc:title'],
      validity: properties['dc:expired'],
      thumbnailUrl,
      attachments: properties['files:files'],
      uid,
      lockOwner,
      workspacePath: path,
      permissions: extractPermissions(entry),
      entry,
    };
  }),
});

export const fetchBannerSetsFromNuxeo = (mediaUrl, pageNumber = 0, skipExired = true, entityPath) => {
  try {
    // Extract and regenerate all paths to look in
    const entitiesPaths = entityPath
      .split('/')
      .slice(3)
      .reduce((values, value) => [...values, `${values[values.length - 1] || ''}/${value}`], []);

    let query = `SELECT * FROM Document WHERE ecm:primaryType = 'BannerSet' AND dc:source = 'adbuilder' AND ecm:isProxy = 0 AND ecm:isTrashed = 0 AND ecm:isCheckedIn = 0 AND (${entitiesPaths
      .map(
        (path, i) =>
          `ecm:path STARTSWITH '/default-domain/workspaces${path}${i === entitiesPaths.length - 1 ? '' : '/assets'}'`,
      )
      .join(' OR ')})`;

    if (skipExired) {
      query += ` AND (dc:expired > TIMESTAMP '${moment().toISOString()}' OR dc:expired IS null)`; // we show documents with dc:expired to NULL
    }

    return doWithToken(token => {
      const url = `${mediaUrl}${baseSearchUrl}?sortBy=dc:modified,dc:expired,dc:title,&sortOrder=DESC,ASC,ASC&pageSize=${pageSize}&currentPageIndex=${pageNumber}&${queryInit}=${encodeURIComponent(
        query,
      )}`;
      return fetch(url, {
        headers: new Headers({
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json+nxrequest',
          'X-NXproperties': 'dublincore, file, files',
          'X-NXfetch.document': 'lock, properties', // to know if the bannerset has been locked because campaign is ongoing
          'X-NXenrichers.document': 'acls', // to know what permissions are given to the banner-set
        }),
        credentials: 'omit',
      })
        .then(res => res.json())
        .then(formatResponse)
        .catch(e => console.error('error in fetching bannerset', e));
    });
  } catch (e) {
    console.error('cant fetch bannerset from nuxeo', e);
  }
};
