import React from 'react';
import PropTypes from 'prop-types';
import 'react-sortable-tree/style.css';
import { Paper, makeStyles } from '@material-ui/core';
import SortableTree, { getNodeAtPath } from 'react-sortable-tree';
import { generateEntitiesTree, updateEntitiesDragNDropped, updateEntitiesExpansion } from '../utils';
import styles from './styles';

// Utils
const useStyles = makeStyles(styles);

const EntitiesPlaceholderRenderer = props => {
  const classes = useStyles(props);
  return <Paper classes={{ root: classes.placeholder }}>No entity</Paper>;
};

const theme = nodeContentRenderer => ({
  scaffoldBlockPxWidth: 64,
  rowHeight: 80,
  nodeContentRenderer,
  placeholderRenderer: EntitiesPlaceholderRenderer,
});

const returnNull = () => null;
const getNodeKey = ({ node }) => node.id;

const EntitiesTree = ({
  entities,
  rootEntityId /* String */,
  filter /* String */,
  updateEntities /* Func */,
  nodeContentRenderer /* Component */,
  setEntityParent,
  canDrag /* Bool */,
}) => {
  const entitiesTree = generateEntitiesTree(entities, rootEntityId, filter);
  if (!entitiesTree || !entitiesTree.length) return null;

  const toggleEntitiesVisibility = ({ node, expanded }) => {
    const newEntities = updateEntitiesExpansion({ entities, node, expanded });
    updateEntities(newEntities);
  };

  const moveEntity = ({ node, nextParentNode }) => {
    const newParentId = nextParentNode ? nextParentNode.id : 0;
    const nextEntities = updateEntitiesDragNDropped({ entities, node, nextParentNode });
    updateEntities(nextEntities);
    setEntityParent(node.id, newParentId);
  };

  const checkCanDrag = ({ node, path }) => {
    if (!canDrag) return false;
    if (!node.parent) return true;
    const parentNode = getNodeAtPath({
      treeData: entitiesTree,
      path: path.slice(0, -1),
      getNodeKey,
    });
    return parentNode && parentNode.node.active;
  };

  return (
    <SortableTree
      treeData={entitiesTree}
      onChange={returnNull}
      onMoveNode={moveEntity}
      onVisibilityToggle={toggleEntitiesVisibility}
      canDrag={checkCanDrag}
      theme={theme(nodeContentRenderer)}
      getNodeKey={getNodeKey}
    />
  );
};

EntitiesTree.propTypes = {
  /* REQUIRED */
  entities: PropTypes.array.isRequired,
  rootEntityId: PropTypes.string.isRequired,
  updateEntities: PropTypes.func.isRequired,
  nodeContentRenderer: PropTypes.func.isRequired,
  /* OPTIONAL */

  // drag-n-drop
  canDrag: PropTypes.bool,
  setEntityParent: PropTypes.func,
  // filter
  filter: PropTypes.string,
};

EntitiesTree.defaultProps = {
  filter: '',
  canDrag: false,
  setEntityParent: (entityId, parentEntityId) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(
        `trying to set to the entity ${entityId} the parent entity id ${parentEntityId}. If you need to, please implement the prop setEntityParent of EntitiesTree`,
      );
    }
    return null;
  },
};

export default EntitiesTree;
