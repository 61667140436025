import React from 'react';
import {
  Paper,
  List,
  ListItem,
  Typography,
  Chip,
  ListItemText,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import clsx from 'clsx';
import { Image as ImageIcon, Warning as WarningIcon } from '@material-ui/icons';
import EntityCheckbox from './EntityCheckbox';
import EntityMenu from './EntityMenu';
import TreeMoreIcon from './TreeMoreIcon';

function isDescendant(older, younger) {
  return (
    !!older.children &&
    typeof older.children !== 'function' &&
    older.children.some(child => child === younger || isDescendant(child, younger))
  );
}

const EntityNodeContentRenderer = props => {
  // conditions
  const {
    draggedNode,
    didDrop,
    isDragging,
    canDrag,
    currentEntityId,
    node,
    notSelectable,
    warning,
    alwaysShowCheckBoxes,
  } = props;

  const isDraggedDescendant = draggedNode && isDescendant(draggedNode, node);
  const isLandingPadActive = !didDrop && isDragging;
  // render
  const {
    canDrop,
    title,
    subtitle,
    isSearchMatch,
    isSearchFocus,
    className: classNameProp,
    style: styleProp,
    classes,
    selectedEntityId,
    handleClickNode,
    treeIndex,
  } = props;

  return (
    <Paper
      classes={{
        root: clsx(
          classes.nodePaper,
          {
            [classes.dragDisabled]: !canDrag,
            [classes.active]: isLandingPadActive || selectedEntityId === node.id,
            [classes.cancel]: isLandingPadActive && !canDrop,
            [classes.searchMatch]: isSearchMatch,
            [classes.searchFocus]: isSearchFocus,
            [classes.notSelectable]: notSelectable,
          },
          classNameProp,
        ),
      }}
      style={{
        opacity: isDraggedDescendant ? 0.75 : !node.active ? 0.5 : 1,
        ...styleProp,
      }}
      elevation={1}
    >
      <List component="div" disablePadding>
        <ListItem
          id={`cy-user-selectentity-${treeIndex}`}
          component="div"
          onClick={handleClickNode}
          classes={{
            root: classes.listItem,
            button: classes.listItemButton,
          }}
          button={!canDrag}
        >
          <ListItemAvatar>
            <Avatar classes={{ root: currentEntityId === node.id && classes.iconSelected }} src={node.logoUrl}>
              {!node.logoUrl && <ImageIcon />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            classes={{ root: classes.listItemText }}
            disableTypography
            primary={
              <Typography variant="subtitle1" classes={{ root: classes.entityTitle }}>
                {title || node.name}
              </Typography>
            }
            secondary={
              <Typography variant="body1" classes={{ root: classes.entitySubTitle }}>
                {subtitle || ''}
              </Typography>
            }
          />
          <div className={classes.tagCounts}>
            {node.childrenTagCounts &&
              Object.keys(node.childrenTagCounts)
                .sort()
                .map(tagName => (
                  <Chip
                    key={tagName}
                    label={`${tagName} (${node.childrenTagCounts[tagName]})`}
                    classes={{ root: classes.chip }}
                  />
                ))}
          </div>

          {warning && (
            <Tooltip title={warning} className={classes.warning}>
              <IconButton aria-label="warning" color="secondary">
                <WarningIcon />
              </IconButton>
            </Tooltip>
          )}
          <ListItemSecondaryAction>
            {!warning && (
              <EntityCheckbox
                {...props}
                onCheckboxClick={handleClickNode}
                checkBoxesActivated={alwaysShowCheckBoxes || node.active}
              />
            )}
            <EntityMenu icon={<TreeMoreIcon />} {...props} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Paper>
  );
};

export default EntityNodeContentRenderer;
