import moment from 'moment';

const showAsExpiredBeforeCampaignStart = (bannerSet, campaignStartDate) => {
  return Boolean(
    bannerSet.validity &&
      campaignStartDate &&
      moment(bannerSet.validity).isBetween(moment(), moment(campaignStartDate)),
  );
};

export default showAsExpiredBeforeCampaignStart;
