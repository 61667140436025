import React from 'react';
import { withTheme, SvgIcon } from '@material-ui/core';

const TreeExpandIcon = ({ theme, ...props }) => (
  <SvgIcon viewBox="0 0 22 22" {...props}>
    <svg width="22" height="22" viewBox="0 0 22 22">
      <g fill="none">
        <circle cx="11" cy="11" r="11" fill={theme.palette.primary.main} />
        <polygon
          fill="#FFF"
          points="10.5 5.5 11.5 5.5 11.5 10.5 16.5 10.5 16.5 11.5 11.5 11.5 11.5 16.5 10.5 16.5 10.5 11.5 5.5 11.5 5.5 10.5 10.5 10.5"
        />
      </g>
    </svg>
  </SvgIcon>
);

export default withTheme(TreeExpandIcon);
