import { doWithToken } from '../keycloak/utils';
import { baseSearchUrl, queryInit } from './urls';

export const fetchWorkspaceFromNuxeo = (mediaUrl, entityId) => {
  let query = `SELECT * FROM Document WHERE ecm:primaryType = 'Workspace' AND ecm:name = '${entityId}' AND ecm:isProxy = 0 AND ecm:isTrashed = 0`;
  return doWithToken(token => {
    const url = `${mediaUrl}${baseSearchUrl}?${queryInit}=${encodeURIComponent(query)}`;
    return fetch(url, {
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-type': 'application/json+nxrequest',
        'X-NXproperties': 'dublincore, file, files',
        'X-NXfetch.document': 'lock, properties', // to know if the bannerset has been locked because campaign is ongoing
        'X-NXenrichers.document': 'acls', // to know what permissions are given to the banner-set
      }),
      credentials: 'omit',
    })
      .then(res => res.json())
      .catch(e => console.error('error in fetching workspace', e));
  });
};
