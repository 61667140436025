import moment from 'moment';
import showAsExpiredBeforeCampaignStart from './showAsExpiredBeforeCampaignStart';

const showValidityWarning = (bannerSet, campaign) => {
  return (
    bannerSet.validity &&
    campaign.startDate &&
    campaign.endDate &&
    !showAsExpiredBeforeCampaignStart(bannerSet, campaign.startDate) &&
    moment(bannerSet.validity).isBetween(moment(campaign.startDate), moment(campaign.endDate))
  );
};

export default showValidityWarning;
