import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import './sortable-tree-theme.css';
import styles from './styles';
import EntityNodeContainer from './entity-sub-components/EntityNodeContainer';
import EntityNodeContentRenderer from './entity-sub-components/EntityNodeContentRenderer';
import clsx from 'clsx';

const Entity = props => {
  const {
    connectDragPreview,
    checkBoxesActivated,
    checkBoxChecked,
    node,
    handleEntityClick,
    handleEntityCheckBoxClick,
    classes,
    canDrag,
    notSelectable: notSelectableProp,
    showCheckBox,
  } = props;

  const notSelectable = notSelectableProp || (!canDrag && checkBoxesActivated && !showCheckBox);

  const handleClickNode = e => {
    if (checkBoxesActivated) {
      handleEntityCheckBoxClick(!checkBoxChecked, node.id);
      return;
    }
    handleEntityClick(node.id);
  };

  const nodeContent = connectDragPreview(
    // Only native element nodes can now be passed to React DnD connectors
    <div
      className={clsx(classes.nodeContainer, {
        [classes.nodeContainerNoDrag]: !canDrag,
        [classes.notSelectable]: notSelectable,
      })}
    >
      <EntityNodeContentRenderer {...props} notSelectable={notSelectable} handleClickNode={handleClickNode} />
    </div>,
  );
  return <EntityNodeContainer {...props} nodeContent={nodeContent} />;
};

Entity.propTypes = {
  // sortable-tree available props
  buttons: PropTypes.arrayOf(PropTypes.node),
  canDrag: PropTypes.bool,
  className: PropTypes.string,
  icons: PropTypes.arrayOf(PropTypes.node),
  isSearchFocus: PropTypes.bool,
  isSearchMatch: PropTypes.bool,
  node: PropTypes.shape({}).isRequired,
  path: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  style: PropTypes.shape({}),
  subtitle: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  swapDepth: PropTypes.number,
  swapFrom: PropTypes.number,
  swapLength: PropTypes.number,
  title: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  treeIndex: PropTypes.number.isRequired,
  treeId: PropTypes.string.isRequired,
  rowDirection: PropTypes.string.isRequired,

  // Drag and drop API functions
  // Drag source
  connectDragPreview: PropTypes.func.isRequired,
  connectDragSource: PropTypes.func.isRequired,
  didDrop: PropTypes.bool.isRequired,
  draggedNode: PropTypes.shape({}),
  isDragging: PropTypes.bool.isRequired,
  parentNode: PropTypes.shape({}), // Needed for dndManager
  // Drop target
  canDrop: PropTypes.bool,
  isOver: PropTypes.bool.isRequired,

  // Custom props
  currentEntityId: PropTypes.string,
  selectedEntityId: PropTypes.string,
  notSelectable: PropTypes.bool,
  checkBoxesActivated: PropTypes.bool,
  showCheckBox: PropTypes.bool,
  checkBoxChecked: PropTypes.bool,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      action: PropTypes.func,
    }),
  ),
  menuDisabled: PropTypes.bool,
  isUpdating: PropTypes.bool,
  containerProps: PropTypes.node,
  handleEntityCheckBoxClick: PropTypes.func,
  handleEntityClick: PropTypes.func,
};

Entity.defaultProps = {
  // sortable-tree settings => NO TOUCH
  canDrag: false,
  canDrop: false,
  className: '',
  draggedNode: null,
  isSearchFocus: false,
  isSearchMatch: false,
  parentNode: null,
  style: {},
  subtitle: null,
  swapDepth: null,
  swapFrom: null,
  swapLength: null,
  title: null,

  // custom props
  currentEntityId: '',
  selectedEntityId: '',
  notSelectable: false,
  checkBoxesActivated: false,
  showCheckBox: false,
  checkBoxChecked: false,
  isUpdating: false,
  menuItems: [],
  menuDisabled: false,
  handleEntityCheckBoxClick: (checked, id) =>
    console.error(
      `you toggle the checkbox from entity id ${id} to ${checked} but you didn't implement the function handleEntityCheckBoxClick in the component Entity. Please do.`,
    ),
  handleEntityClick: id =>
    console.error(
      `you clicked on entity id ${id} but you didn't implement the function handleEntityCheckBoxClick in the component Entity. Please do.`,
    ),
};

export default withStyles(styles)(Entity);
