const updateEntities = (entities = [], previousEntities = []) => {
  /**
   * Generates tree list based on previous list
   * to keep view tree properties
   */
  let newEntities = [...entities];
  if (previousEntities && previousEntities.length) {
    newEntities = newEntities.map(entity => {
      const previousEntity = previousEntities.find(previousEntity => entity.id === previousEntity.id);
      if (previousEntity) {
        return {
          ...previousEntity,
          ...entity,
        };
      } else {
        return {
          ...entity,
          expanded: false,
        };
      }
    });
  }

  return newEntities;
};

export default updateEntities;
