const updateEntitiesDragNDropped = ({ entities, node, nextParentNode }) => {
  const nextEntities = [...entities];
  const newParentId = nextParentNode ? nextParentNode.id : 0;
  const movedEntityIndex = entities.findIndex(entity => node.id === entity.id);
  nextEntities[movedEntityIndex].parent = newParentId;
  if (nextParentNode) {
    const parentEntityIndex = entities.findIndex(entity => nextParentNode.id === entity.id);
    nextEntities[parentEntityIndex].expanded = true;
  }
  return nextEntities;
};

export default updateEntitiesDragNDropped;
