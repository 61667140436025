import { getTreeFromFlatData } from 'react-sortable-tree';

const generateEntitiesTree = (entities = [], rootEntityId = '', filter = null) => {
  const entitiesWithParent = entities
    .map(entity => (entity.parent ? entity : { ...entity, parent: '' }))
    .filter(entity => (filter === 'active' ? entity.active : true));

  const rootEntity = entitiesWithParent.find(({ id }) => id === rootEntityId);

  return getTreeFromFlatData({
    flatData: entitiesWithParent,
    getKey: node => node.id,
    getParentKey: node => node.parent,
    rootKey: rootEntity.parent,
  });
};

export default generateEntitiesTree;
