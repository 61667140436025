import React from 'react';
import { IconButton } from '@material-ui/core';
import TreeCollapseIcon from './TreeCollapseIcon';
import TreeExpandIcon from './TreeExpandIcon';

const EntityNodeContainer = ({
  scaffoldBlockPxWidth,
  toggleChildrenVisibility,
  connectDragSource,
  canDrag,
  node,
  path,
  treeIndex,
  classes,
  containerProps,
  nodeContent,
}) => (
  <div className={classes.root} {...containerProps}>
    {toggleChildrenVisibility && node.children && (node.children.length > 0 || typeof node.children === 'function') && (
      <div
        className={classes.expandButtonContainer}
        style={{ left: -scaffoldBlockPxWidth, width: scaffoldBlockPxWidth }}
      >
        <IconButton
          id={`cy-campaign-expandentities${node.parent ? `-${treeIndex}` : ''}`}
          type="button"
          color="primary"
          aria-label={node.expanded ? 'Collapse' : 'Expand'}
          onClick={() => toggleChildrenVisibility({ node, path, treeIndex })}
        >
          {node.expanded ? <TreeCollapseIcon /> : <TreeExpandIcon />}
        </IconButton>
      </div>
    )}
    {canDrag ? connectDragSource(nodeContent, { dropEffect: 'copy' }) : nodeContent}
  </div>
);

export default EntityNodeContainer;
