import { addPermissionUrl } from './urls';
import { extractPermissions } from './extractPermissions';
import { doWithToken } from '../keycloak';

export const setBannerSetPermission = (mediaUrl, uid, entityId, permission = 'Read') => {
  try {
    return doWithToken(token => {
      const url = `${mediaUrl}${addPermissionUrl}`;
      return fetch(url, {
        method: 'POST',
        headers: new Headers({
          Accept: 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-type': 'application/json',
          'X-NXenrichers.document': 'acls', // to know what permissions are given to the banner-set
        }),
        body: JSON.stringify({
          input: uid, // banner-set uid
          params: {
            username: `${permission.toUpperCase()}_${entityId}`,
            permission,
          },
        }),
      })
        .then(res => res.json())
        .then(entry => extractPermissions(entry))
        .catch(e => console.error('error in setting permissions in nuxeo', e));
    });
  } catch (e) {
    console.error('cant set permission in nuxeo', e);
  }
};
