export const extractPermissions = entry =>
  entry.contextParameters.acls.map(({ aces }) => aces.map(({ id }) => id)).reduce((acc, val) => acc.concat(val), []); // flatten

/*
EXAMPLE OF AN ENTRY
{
    "entity-type": "document",
    "repository": "default",
    "uid": "7b48635c-c880-41fe-b2a4-e40832b59a6d",
    "path": "/default-domain/workspaces/375053d9-546a-44d6-83f9-fbc6a1a2eff0/ca0b2b9d-0b52-42bf-9e1d-a71c78f8e040/assets/PIZZA ANANAS",
    "type": "BannerSet",
    "state": "project",
    "parentRef": "a9d83f9f-807a-4786-ad82-455a499646ca",
    "isCheckedOut": true,
    "isVersion": false,
    "isProxy": false,
    "changeToken": "3-0",
    "isTrashed": false,
    "title": "PIZZA ANANAS",
    "lastModified": "2019-04-03T13:28:03.184Z",
    "facets": [
        "Versionable",
        "NXTag",
        "Commentable",
        "HasRelatedText",
        "Thumbnail",
        "Downloadable"
    ],
    "contextParameters": {
        "acls": [
            {
                "name": "local",
                "aces": [
                    {
                        "id": "READ_42cdc7e5-cee9-49a9-95f3-dd35f33f97df:Read:true:france@pizza-hut.pizza::",
                        "username": "READ_42cdc7e5-cee9-49a9-95f3-dd35f33f97df",
                        "externalUser": false,
                        "permission": "Read",
                        "granted": true,
                        "creator": "france@pizza-hut.pizza",
                        "begin": null,
                        "end": null,
                        "status": "effective"
                    }
                ]
            },
            {
                "name": "inherited",
                "aces": [
                    {
                        "id": "READ_ca0b2b9d-0b52-42bf-9e1d-a71c78f8e040:Read:true:nuxeo-admin::",
                        "username": "READ_ca0b2b9d-0b52-42bf-9e1d-a71c78f8e040",
                        "externalUser": false,
                        "permission": "Read",
                        "granted": true,
                        "creator": "nuxeo-admin",
                        "begin": null,
                        "end": null,
                        "status": "effective"
                    },
                    {
                        "id": "WRITE_ca0b2b9d-0b52-42bf-9e1d-a71c78f8e040:ReadWrite:true:nuxeo-admin::",
                        "username": "WRITE_ca0b2b9d-0b52-42bf-9e1d-a71c78f8e040",
                        "externalUser": false,
                        "permission": "ReadWrite",
                        "granted": true,
                        "creator": "nuxeo-admin",
                        "begin": null,
                        "end": null,
                        "status": "effective"
                    },
                    {
                        "id": "READ_375053d9-546a-44d6-83f9-fbc6a1a2eff0:Read:true:nuxeo-admin::",
                        "username": "READ_375053d9-546a-44d6-83f9-fbc6a1a2eff0",
                        "externalUser": false,
                        "permission": "Read",
                        "granted": true,
                        "creator": "nuxeo-admin",
                        "begin": null,
                        "end": null,
                        "status": "effective"
                    },
                    {
                        "id": "WRITE_375053d9-546a-44d6-83f9-fbc6a1a2eff0:ReadWrite:true:nuxeo-admin::",
                        "username": "WRITE_375053d9-546a-44d6-83f9-fbc6a1a2eff0",
                        "externalUser": false,
                        "permission": "ReadWrite",
                        "granted": true,
                        "creator": "nuxeo-admin",
                        "begin": null,
                        "end": null,
                        "status": "effective"
                    },
                    {
                        "id": "Administrator:Everything:true:::",
                        "username": "Administrator",
                        "externalUser": false,
                        "permission": "Everything",
                        "granted": true,
                        "creator": null,
                        "begin": null,
                        "end": null,
                        "status": "effective"
                    }
                ]
            }
        ]
    }
}
 */
