const updateEntitiesExpansion = ({ entities, node, expanded }) => {
  const index = entities.findIndex(({ id }) => id === node.id);
  return [
    ...entities.slice(0, index),
    {
      ...entities[index],
      expanded,
    },
    ...entities.slice(index + 1),
  ];
};

export default updateEntitiesExpansion;
