import Keycloak from 'keycloak-js';

let keycloak;

// Litle Deferred Object to deffer keycloak base actions
let resolveKeycloakIsInitialized, rejectKeycloakIsInitialized;

const keycloakIsInitialized = new Promise((resolve, reject) => {
  resolveKeycloakIsInitialized = resolve;
  rejectKeycloakIsInitialized = reject;
});
// End Deferred Object

const login = redirectUri => {
  if (redirectUri) {
    return keycloak.login({ redirectUri });
  }
  return keycloak.login();
};

const logout = () => {
  keycloak.clearToken();
  return keycloak.logout();
};

/**
 * Transforms keycloak internal state
 */
const transformKeycloakAuth = ({
  authenticated,
  token,
  refreshToken,
  idToken,
  timeSkew,
  realmAccess: { roles = [] } = {},
  tokenParsed: {
    preferred_username: username,
    given_name: firstName,
    family_name: lastName,
    email,
    entity_attribute: entityId,
  } = {},
  subject,
}) => ({
  authenticated,
  token,
  refreshToken,
  idToken,
  timeSkew,
  user: {
    id: subject,
    username,
    firstName,
    lastName,
    email,
    roles,
    entityId,
  },
});

/**
 * Find current identity provider based on current location sub domain
 *
 * @returns current sub domain as an idpHint, or undefined if no sub domain found
 */
const findIdpHint = domain => {
  const { host } = window.location;
  // Don't try to use an IDP on main application domain
  if (!domain || domain === host) {
    return undefined;
  }
  // We can not have multiple sub domains as the reverse proxy will return 404 for *.*.appDomain
  return host.split('.')[0];
};

const parseJwt = token => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  return JSON.parse(window.atob(base64));
};

const updateToken = (minValidity = 5) =>
  new Promise((resolve, reject) => {
    keycloak
      .updateToken(minValidity)
      .success(resolve)
      .error(e => reject(new Error('Session may have expired'), e));
  });

const doWithToken = action => keycloakIsInitialized.then(() => updateToken()).then(() => action(keycloak.token));

/**
 * Initialize keycloak with configuration and stored authentication information
 *
 * @see https://www.keycloak.org/docs/latest/securing_apps/index.html#_javascript_adapter
 *
 * Known issue with promise rejection if we try to initialize with an expired token
 * @see https://issues.jboss.org/browse/KEYCLOAK-7920
 *
 * @param keycloakConfig SSO configuration coming from configuration file (fallback with storage)
 * @param domain IDP domain
 * @param debug for handling logs
 * @param loginCompulsory true for 'login-required', false for 'check-sso'
 * @param loginAcceptableTimeout 5000 by default, a timeout if keycloak does not answer in the given amount of time
 * @param initConfig initialization configuration, check options at
 * https://www.keycloak.org/docs/latest/securing_apps/index.html#init-options
 * @returns {Promise}
 */

const keycloakInit = ({
  keycloakConfig,
  initConfig = {},
  domain,
  debug,
  loginCompulsory = true,
  loginAcceptableTimeout = 5000,
}) => {
  try {
    let initialized = false;
    debug('Keycloak init', keycloakConfig, initConfig);
    keycloak = Keycloak(keycloakConfig);

    // Override Keycloak's createLoginUrl method as there is not native solution
    // to pass and Identity Provider in Keycloak's init method initOptions
    const idpHint = findIdpHint(domain);
    const { createLoginUrl: initialCreateLoginUrl } = keycloak;
    keycloak.createLoginUrl = options => initialCreateLoginUrl({ ...options, idpHint });
    const initOptions = {
      ...initConfig,
      onLoad: loginCompulsory ? 'login-required' : 'check-sso',
      checkLoginIframe: window.location.host !== 'campagnespubdigitales.mazdafrance.fr',
    };
    debug('Keycloak init options', initOptions);
    const auth = new Promise((resolve, reject) => {
      keycloak
        .init(initOptions)
        .then(authenticated => {
          // Transform keycloak outcome
          initialized = true;
          const result = transformKeycloakAuth(keycloak);
          debug('Authentication initialized [%s]\nUser Information [%o]', authenticated, result.user);

          updateToken()
            .then(() => {
              resolve(result);
              resolveKeycloakIsInitialized();
            })
            .catch(err => {
              reject(err || new Error('Failed to initialize keycloak'));
            });
        })
        .catch(err => {
          reject(err || new Error('Failed to initialize keycloak'));
        });
    });

    // if there is a problem with keycloak.init, there is no rejection of the promise and no error is thrown
    // so we need to handle it manually
    const timeout = new Promise((_, reject) => {
      setTimeout(() => {
        if (!initialized) {
          rejectKeycloakIsInitialized();
          reject(
            new Error(
              'an error happened during the initialization of authentification process,' +
                ' please contact the administrator',
            ),
          );
        }
      }, loginAcceptableTimeout);
    });

    return Promise.race([timeout, auth]);
  } catch (e) {
    console.error('error while keyclock init', e);
    return {
      authenticated: false,
    };
  }
};

const hasRole = (...args) => (args.length > 0 ? keycloak.hasRealmRole(...args) : true);

export { parseJwt, updateToken, doWithToken, keycloakInit, login, logout, hasRole };
