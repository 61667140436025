import { createStyles } from '@material-ui/core';
import { decomposeColor, emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme =>
  createStyles({
    placeholder: {
      display: 'flex',
      width: '100%',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'center',
    },
    root: {
      flex: 1,
      maxWidth: '100%',
      paddingRight: theme.spacing(0.5),
    },
    nodeContainer: {
      flex: 1,
      cursor: 'move',
    },
    nodeContainerNoDrag: {
      cursor: 'pointer',
    },
    nodePaper: {
      position: 'relative',
      flex: 1,
      border: '1px solid transparent',
      boxShadow: '0 1px 4px 1px rgba(155,155,155,0.17)',
      transition: theme.transitions.create('border-color', {
        duration: theme.transitions.duration.shortest,
      }),
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
        boxShadow: `0 1px 9px 1px rgba(${decomposeColor(emphasize(theme.palette.primary.main, 0.2)).values.join(
          ',',
        )},0.3)`,
      },
    },
    warning: {
      marginRight: -20,
    },
    active: {
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: `0 1px 9px 1px rgba(${decomposeColor(emphasize(theme.palette.primary.main, 0.2)).values.join(
        ',',
      )},0.3)`,
    },
    listItem: {
      paddingRight: theme.spacing(4),
      height: 74,
    },
    listItemText: {
      flexShrink: 0,
      flexBasis: theme.spacing(30),
      marginRight: theme.spacing(4),
    },
    listItemButton: {
      '&:hover': {
        backgroundColor: 'unset',
        color: theme.palette.primary.main,
      },
      '&:focus': {
        backgroundColor: 'unset',
        color: theme.palette.primary.main,
      },
    },
    notSelectable: {
      cursor: 'default',
    },
    expandButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
    },
    chip: {
      marginRight: theme.spacing(2),
      color: theme.palette.primary.main,
      backgroundColor: '#D9ECEE',
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
    },
    subtitle: {
      color: theme.palette.text.secondary,
    },
    iconSelected: {
      backgroundColor: theme.palette.primary.main,
      border: `4px solid ${theme.palette.primary.main}`,
    },
    entityTitle: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
    },
    entitySubTitle: {
      color: '#9B9B9B',
    },
    tagCounts: {
      overflowX: 'hidden',
      position: 'relative',
      pointerEvents: 'none',
      flexShrink: 1,
      flexGrow: 10,
      marginRight: theme.spacing(2),
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        width: theme.spacing(4),
        background: 'linear-gradient(to left, rgba(255,255,255,1), rgba(255,255,255,0))',
      },
    },
    entityLogo: {
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    menuIconLeft: {
      marginRight: theme.spacing(1),
    },
    menuPaper: {
      minWidth: 128,
      boxShadow: '1px 3px 10px 2px rgba(0,0,0,0.27)',
    },
  });

export default styles;
