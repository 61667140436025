import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import loggedOutMessageIntlMessagesPropTypes from '../reference/loggedOutMessageIntlMessagesPropTypes';
import { login } from '../utils';

const styles = {
  root: {
    textAlign: 'center',
  },
};

const LoggedOutMessage = ({ classes, redirectUrl, intlMessages, locale }) => {
  const onClick = () => (Boolean(redirectUrl) ? login(redirectUrl) : login());
  return (
    <div className={classes.root}>
      <Typography variant="body2">{intlMessages[locale]['app.authentication-not-logged']}</Typography>
      <Button onClick={onClick} color="inherit">
        {intlMessages[locale]['app.menu.login']}
      </Button>
    </div>
  );
};

LoggedOutMessage.propTypes = {
  classes: PropTypes.object.isRequired,
  login: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string,
  locale: PropTypes.string.isRequired,
  intlMessages: PropTypes.shape({
    en: PropTypes.shape(loggedOutMessageIntlMessagesPropTypes.en).isRequired,
    fr: PropTypes.shape(loggedOutMessageIntlMessagesPropTypes.fr).isRequired,
  }),
};

LoggedOutMessage.defaultProps = {
  redirectUrl: undefined,
};

export default withStyles(styles)(LoggedOutMessage);
