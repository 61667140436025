import React from 'react';
import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';

const EntityCheckbox = props => {
  const { checkBoxChecked, showCheckBox, checkBoxesActivated, onCheckboxClick, disabled } = props;
  if (!checkBoxesActivated) return null;

  return (
    <Checkbox
      checked={checkBoxChecked}
      onChange={onCheckboxClick}
      color="primary"
      style={{
        visibility: showCheckBox ? 'visible' : 'hidden',
      }}
      disabled={disabled || !showCheckBox}
    />
  );
};

EntityCheckbox.propTypes = {
  checkBoxChecked: PropTypes.bool.isRequired,
  showCheckBox: PropTypes.bool,
  checkBoxesActivated: PropTypes.bool,
  onCheckboxClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

EntityCheckbox.defaultProps = {
  showCheckBox: true,
  checkBoxesActivated: true,
  disabled: false,
};

export default EntityCheckbox;
