const padding = 16;
const gridGap = 20;
const dialogWidth = 600;
const cardHeight = 150;
export const styles = theme => ({
  dialogRoot: {
    overflow: 'hidden',
  },
  dialogContent: {
    padding,
    marginBottom: padding,
    boxSizing: 'border-box',
    flexShrink: 1,
    // minHeight: 400,
    '& > *': {
      padding,
    },
    '& *': {
      boxSizing: 'border-box',
    },
    width: dialogWidth,
  },
  infiniteScroll: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridAutoRows: `${cardHeight}px`,
    gridGap: `${gridGap}px`,
    width: '100%',
    // minHeight: 500,
  },
  thumbnail: {
    width: '100%',
    height: cardHeight,
    flexShrink: 0,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    border: '1px solid rgba(230,230,230,1)',
    borderRadius: 3,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  thumbnailContainer: {
    width: (dialogWidth - 2 * gridGap - 4 * padding) / 3,
    maxWidth: '100%',
    minHeight: '100%',
    boxSizing: 'border-box',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    position: 'relative',
    background: 'white',
    border: '2px solid transparent',
    '&:hover': {
      '& $thumbnail': {
        boxShadow: theme.shadows[2],
        border: 'none',
      },
      '& $title': {
        fontWeight: 'bold',
        background: 'rgba(0,0,0,0.6)',
      },
    },
  },
  unavailableThumbnailContainer: {
    pointerEvents: 'none',
    filter: 'grayscale(100%)',
  },
  unavailableThumbnail: {
    opacity: 0.3,
    '&:hover': {
      opacity: 0.7,
    },
  },
  unavailableText: {
    position: 'absolute',
    filter: 'grayscale(100%)',
    top: 5,
    left: 0,
    right: 0,
    textAlign: 'center',
  },
  cardIsSelected: {
    border: `2px solid ${theme.palette.secondary.main}`,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: cardHeight,
    pointerEvents: 'none',
    padding,
  },
  title: {
    textTransform: 'uppercase',
    fontSize: 15,
    background: 'rgba(0,0,0,0.4)',
    minHeight: '15%',
    color: 'white',
    maxWidth: '100%',
    textAlign: 'left',
    flexGrow: 1,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: 10,
    overflow: 'hidden',
    whiteSpace: 'wrap',
    textOverflow: 'ellipsis',
    pointerEvents: 'none',
  },
  loader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gridColumnEnd: 'span 3',
  },
  message: {
    gridColumnEnd: 'span 3',
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexShrink: 0,
  },
  dialogSubtitle: {
    marginBottom: theme.spacing(3),
  },
  expiredBannersCheckbox: {
    marginTop: theme.spacing(-3),
  },
  lockIcon: {
    opacity: 0.35,
    position: 'absolute',
    top: 5,
    left: 5,
    cursor: 'default',
  },
  warnIcon: {
    opacity: 0.35,
    position: 'absolute',
    top: 5,
    left: 15,
    cursor: 'default',
  },
  errorIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    cursor: 'default',
    color: 'red',
  },
  validityWarnIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    cursor: 'default',
    color: '#FFCF15',
  },
});
