import findEntity from './findEntity';
import makeEntityExpanded from './makeEntityExpanded';

const expandEntitiesTreeToEntity = (entities = [], entityId = null) => {
  // target: expand entities to the entity which owns the banner-set, if any
  try {
    if (!entityId) return entities;
    let closestEntity = findEntity(entities, entityId);
    if (!closestEntity) return entities; // the owner of the bannerset is not the entity or one of its child => the bannerset should be read only
    // throw new Error('there should be an entity owner of the bannerset, it is strange, please inquiry');
    if (!closestEntity.parent) return entities; // no need to expand
    let isParent = true;
    let newEntities = entities;
    while (isParent) {
      let parentEntityId = closestEntity.parent;
      const parentEntity = findEntity(entities, parentEntityId);
      if (!parentEntity) break;
      newEntities = makeEntityExpanded(newEntities, parentEntityId);
      closestEntity = parentEntity;
      if (!parentEntity.parent) isParent = false;
    }
    return newEntities;
  } catch (e) {
    return entities;
  }
};

export default expandEntitiesTreeToEntity;
