import PropTypes from 'prop-types';

export default {
  en: {
    'app.authentication-not-logged': PropTypes.string.isRequired,
    'app.menu.login': PropTypes.string.isRequired,
  },
  fr: {
    'app.authentication-not-logged': PropTypes.string.isRequired,
    'app.menu.login': PropTypes.string.isRequired,
  },
};
