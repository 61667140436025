import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
  CircularProgress,
  DialogContent,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContentText,
  Tooltip,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import InfiniteScroll from 'react-infinite-scroller';
import LockIcon from '@material-ui/icons/Lock';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircle from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

import { fetchBannerSetsFromNuxeo } from '../../nuxeo-helpers';
import { styles } from './styles';
import {
  showAsLocked,
  showAsUnavailable,
  showValidityWarning,
  bannerSetIsSelected,
  showAsExpiredBeforeCampaignStart,
  showAsExpired,
  isUnavailableThumbnailContainer,
} from '../utils';
import { fetchWorkspaceFromNuxeo } from '../../nuxeo-helpers/fetchWorkspaceFromNuxeo';

class OpenBannerSetFromNuxeo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerSets: null,
      hasMore: true,
      selectedBannersets: [],
      skipExpired: true,
    };
  }

  componentDidMount() {
    // hack to not setState if the user give up the dialog before the fetchBannerSetsFromNuxeo is over
    this.mounted = true;
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  fetchThumbnails = async (page, skipExpired = true) => {
    try {
      if (!this.mounted) return;
      const { mediaUrl, fetchBannerSetsFromNuxeo: getBannerSets, userEntityId } = this.props;

      // Fetch entity workspace
      const workspace = await fetchWorkspaceFromNuxeo(mediaUrl, userEntityId);

      // Get Banner sets
      const response = await getBannerSets(mediaUrl, page, skipExpired, workspace.entries[0].path);
      const { bannerSets, numberOfPages } = response;
      this.setState(({ bannerSets: bannerSetsState, hasMore, page0alreadyLoaded, skipExpired: valid }) => {
        // banner-sets
        let nextBannerSetsState = null;
        const withBannerSetState = bannerSetsState && bannerSetsState.length;
        const withNewBannerSets = bannerSets && bannerSets.length;
        const withBoth = withBannerSetState && withNewBannerSets;
        if (valid === skipExpired) {
          if (withBoth) {
            nextBannerSetsState = [...bannerSetsState, ...bannerSets];
          } else if (withNewBannerSets) {
            nextBannerSetsState = [...bannerSets];
          } else if (withBannerSetState) {
            nextBannerSetsState = [...bannerSetsState];
          }
        } else {
          if (withNewBannerSets) {
            nextBannerSetsState = [...bannerSets];
          } else {
            nextBannerSetsState = [];
          }
        }

        let stillHasMore; // fixing bug where the results from page 1 and 2 come before the results of page 0
        if (!page0alreadyLoaded) {
          stillHasMore = true;
        } else if (withNewBannerSets) {
          stillHasMore = hasMore;
        } else {
          stillHasMore = numberOfPages >= page + 1;
        }

        return {
          bannerSets: nextBannerSetsState,
          hasMore: stillHasMore,
          page0alreadyLoaded: page0alreadyLoaded || page === 0,
          forceNoMore: page === 0, // only used when page 0 has loaded to prevent loader from appearing
          skipExpired,
        };
      });
    } catch (e) {
      const { handleFetchingError } = this.props;
      console.error('not able to fetch thumbnails', e);
      handleFetchingError(e);
      this.setState({ hasMore: false, forceNoMore: true });
    }
  };

  handleBannerSetClick = event => {
    try {
      event.stopPropagation();
      event.preventDefault();
      const { uid } = event.target.dataset;
      if (!uid) return;
      const { allowMultiSelect, openBannerSet } = this.props;
      const { bannerSets } = this.state;
      if (!allowMultiSelect) {
        openBannerSet(bannerSets.find(({ uid: bannerSetUid }) => bannerSetUid === uid));
        return;
      }
      this.setState(({ selectedBannersets }) => ({
        selectedBannersets: bannerSetIsSelected(selectedBannersets, uid)
          ? selectedBannersets.filter(id => id !== uid)
          : [...selectedBannersets, uid],
      }));
    } catch (e) {
      console.error('handleBannerSetClick', e);
    }
  };

  handleValidate = () => {
    try {
      const { addMultipleBannerSets } = this.props;
      const { bannerSets, selectedBannersets } = this.state;
      addMultipleBannerSets(bannerSets.filter(({ uid }) => selectedBannersets.includes(uid)));
    } catch (e) {
      console.error('handleValidate', e);
    }
  };

  render() {
    const { bannerSets, hasMore, selectedBannersets, forceNoMore, skipExpired } = this.state;
    const {
      classes,
      onCloseDialog,
      open,
      userEntityId,
      intlMessages,
      allowUnavailability,
      allowMultiSelect,
      withValidBannerSet,
      campaign,
    } = this.props;

    return (
      <Dialog open={open} onClose={onCloseDialog} classes={{ paper: classes.dialogRoot }}>
        <DialogTitle>{intlMessages.title}</DialogTitle>
        {intlMessages.subtitle && (
          <DialogContentText className={classes.dialogSubtitle}>{intlMessages.subtitle}</DialogContentText>
        )}
        <DialogContent className={classes.dialogContent}>
          {withValidBannerSet && (
            <FormControlLabel
              className={classes.expiredBannersCheckbox}
              control={
                <Checkbox
                  checked={!skipExpired}
                  onChange={(event, checked) => this.fetchThumbnails(0, !checked)}
                  color="primary"
                />
              }
              label={intlMessages.checkboxLabel}
            />
          )}
          <InfiniteScroll
            pageStart={-1} // to start at page 0
            hasMore={hasMore}
            loadMore={this.fetchThumbnails}
            loader={
              forceNoMore ? null : (
                <div className={classes.loader} key="image-loader">
                  <CircularProgress />
                </div>
              )
            }
            useWindow={false}
            className={classes.infiniteScroll}
          >
            {!hasMore && (!bannerSets || !bannerSets.length) && (
              <div className={classes.message} key="no-image">
                {intlMessages['no-results']}
              </div>
            )}
            {bannerSets &&
              bannerSets.length &&
              bannerSets.map((bannerSet, idx) => (
                <div
                  key={`bannerSet-${bannerSet.uid}`}
                  className={clsx(classes.thumbnailContainer, {
                    [classes.unavailableThumbnailContainer]: isUnavailableThumbnailContainer(
                      bannerSet,
                      allowUnavailability,
                      campaign.startDate,
                    ),
                  })}
                >
                  <div
                    id={`cy-campaign-selectbanner-${idx}`}
                    className={clsx(classes.thumbnail, {
                      [classes.unavailableThumbnail]:
                        showAsUnavailable(bannerSet, allowUnavailability) || showAsExpired(bannerSet),
                    })}
                    role="button"
                    tabIndex={idx}
                    style={{ backgroundImage: `url(${bannerSet.thumbnailUrl})` }}
                    data-uid={bannerSet.uid}
                    onKeyDown={this.handleBannerSetClick}
                    onClick={this.handleBannerSetClick}
                  >
                    <span className={classes.title}>{bannerSet.name}</span>
                  </div>
                  {showAsUnavailable(bannerSet, allowUnavailability) && (
                    <Typography className={classes.unavailableText} variant="caption">
                      {intlMessages['not-available']}
                    </Typography>
                  )}
                  {showAsLocked(bannerSet, userEntityId, allowUnavailability) && (
                    <Tooltip title={intlMessages['read-only']}>
                      <LockIcon classes={{ root: classes.lockIcon }} />
                    </Tooltip>
                  )}
                  {showValidityWarning(bannerSet, campaign) && (
                    <Tooltip title={intlMessages['validity-warning']}>
                      <WarningIcon classes={{ root: classes.validityWarnIcon }} />
                    </Tooltip>
                  )}
                  {showAsExpiredBeforeCampaignStart(bannerSet, campaign.startDate) && (
                    <Tooltip title={intlMessages['validity-error']}>
                      <ErrorOutlineIcon color="error" classes={{ root: classes.errorIcon }} />
                    </Tooltip>
                  )}
                  {bannerSetIsSelected(selectedBannersets, bannerSet.uid) && (
                    <div className={classes.cardIsSelected}>
                      <CheckCircle color="secondary" />
                    </div>
                  )}
                </div>
              ))}
          </InfiniteScroll>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button onClick={onCloseDialog}>{intlMessages.cancel}</Button>
          {allowMultiSelect && (
            <Button onClick={this.handleValidate} disabled={!selectedBannersets.length} color="primary">
              {intlMessages.validate}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

OpenBannerSetFromNuxeo.propTypes = {
  /* INTL */
  intlMessages: PropTypes.shape({
    'no-results': PropTypes.string.isRequired,
    'read-only': PropTypes.string.isRequired,
    'not-available': PropTypes.string.isRequired,
    'validity-warning': PropTypes.string,
    'validity-error': PropTypes.string,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string.isRequired,
    validate: PropTypes.string.isRequired,
    cancel: PropTypes.string.isRequired,
    checkboxLabel: PropTypes.string,
  }).isRequired,
  /* NUXEO */
  fetchBannerSetsFromNuxeo: PropTypes.func, // to use with stories
  allowUnavailability: PropTypes.bool,
  allowMultiSelect: PropTypes.bool,
  /* STATE TO PROPS */
  open: PropTypes.bool.isRequired,
  mediaUrl: PropTypes.string.isRequired,
  userEntityId: PropTypes.string,
  /* DISPATCH TO PROPS */
  openBannerSet: PropTypes.func,
  addMultipleBannerSets: PropTypes.func,
  handleFetchingError: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  /* MUI */
  classes: PropTypes.object.isRequired,
  /* Checkbox */
  withValidBannerSet: PropTypes.bool,
  campaign: PropTypes.shape({
    endDate: PropTypes.string,
  }),
};

OpenBannerSetFromNuxeo.defaultProps = {
  fetchBannerSetsFromNuxeo,
  openBannerSet: () => console.error('You did not set-up the prop openBannerSet. Please do.'),
  addMultipleBannerSets: () => console.error('You did not set-up the prop addMultipleBannerSets. Please do.'),
  allowUnavailability: false,
  allowMultiSelect: false,
  userEntityId: '',
  withValidBannerSet: false,
  campaign: {
    startDate: '',
    endDate: '',
  },
};

export default withStyles(styles)(OpenBannerSetFromNuxeo);
